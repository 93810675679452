import React, { useState, useEffect } from 'react';
import {
    IonPage, IonCard, IonCardContent, IonCardHeader, IonCardTitle,
    IonButton, IonInput, IonLabel, IonRow, IonCol, IonIcon,
    IonInfiniteScroll, IonInfiniteScrollContent, IonContent, IonFab, IonFabButton
} from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { AppHeader } from '../../components/app-header';
import './index.css';
import { trashOutline, createOutline } from 'ionicons/icons';
import {
    palletsAction, unmountPalletsAction, palletsMasterDataAction,
    clearPalletsAction, palletItemsAction, savePalletItemAction,
    deletePalletAction, deletePalletItemAction, updatePalletItemAction,
    updatePalletFormData, getItemDescriptionAction, syncPriceAction, syncPriceStatusAction,
    getDIDdescriptionAction,getITOdescriptionNewAction,
    getITODetailsAction,getdidnumbervalidAction,getitonumbervalidAction
} from './action';
import { savePalletAction} from '../pallet/action';
import { SingleSelect } from '../../components/single-select';
import { config, webUrl } from '../../shared/constants';
import { PalletItemModal } from '../../components/pallet-item-modal';
import { AppAlert } from '../../components/app-alert';
import { getUtcDateTime, hasPermission, isValidStatusToChange, getUserStore, getDayDiff, setItemStatusColor, convertTZ } from '../../shared/common';
import { StatusModal } from '../../components/status-modal';
import { updatePalletShippingStatusAction } from '../pallet-booking/action';
import { printLabel } from './label';
import { getITODescriptionAction } from '../pallet-new/action';

const Pallet = (props) => {

    const [detailsToShowIndex, setDetailsToShowIndex] = useState(undefined);
    const [itemDetailsToShowIndex, setItemDetailsToShowIndex] = useState(undefined);
    const [pageNo, setPageNo] = useState(1);
    const [searchData, setSearchData] = useState({ modal: {} });
    const [showModal, setShowModal] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [palletId, setPalletId] = useState(0);
    const [showConfirm, setShowConfirm] = useState(false);
    const [palletItemId, setPalletItemId] = useState(0);
    const [showItemConfirm, setShowItemConfirm] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [updatedStatus, setUpdatedStatus] = useState('');
    const [showSyncConfirm, setShowSyncConfirm] = useState(false);
    const [statusOfRow, setStatusOfRow] = useState('');
    const [sendingInner, setSendingInner] = useState(false);
    const handleDidNumberBlur = async (e) => {
        const didNumber = e.target.value;
        if (didNumber) {
            try {
                // Corrected function name
                const response = await props.getdidnumbervalid(didNumber);
                // console.log("tttt",response,didNumber);
    
                // Check if the response is defined and is an array
                if (!response ||  response[0]?.Error) {
                    setAlertMessage('Invalid DID Number entered');
                    setShowAlert(true);
                } else {
                    // Clear ito_Number if did_Number is filled and valid
                    onModalFieldChange('ito_Number', '');
                    setShowAlert(false); // Hide alert if DID is valid
                }
            } catch (error) {
                console.error('Error validating DID Number:', error);
                setAlertMessage('Error validating DID Number');
                setShowAlert(true);
            }
        }
    };
    
    
    const handleItoNumberBlur = async (e) => {
        const itoNumber = e.target.value;
        if (itoNumber) {
            try {
                // Correct function name here
                const response = await props.getitonumbervalid(itoNumber);
                if (!response || response[0]?.Error) {
                    setAlertMessage('Invalid ITO Number entered');
                    setShowAlert(true);
                } else {
                    onModalFieldChange('did_Number', '');
                    setShowAlert(false);
                }
            } catch (error) {
                console.error('Error validating ITO Number:', error);
                setAlertMessage('Error validating ITO Number');
                setShowAlert(true);
            }
        }
    };
    

    
    const handleFieldChange = (name, value) => {
        setSearchData(prevFormData => {
            const updatedModal = { ...prevFormData.modal, [name]: value };
            
            // Disable did_Number if ito_Number is filled and vice versa
            if (name === 'did_Number' && value) {
                updatedModal.ito_Number = '';  // Clear ito_Number if did_Number is filled
            } else if (name === 'ito_Number' && value) {
                updatedModal.did_Number = '';  // Clear did_Number if ito_Number is filled
            }
    
            const quantity = parseInt(updatedModal.quantity) || 0;
            const qtyToSend = parseInt(updatedModal.qty_to_send) || 0;
            const totalCartons = parseInt(updatedModal.cartons_send) || 0;
            const doneCartons = parseInt(updatedModal.done_cartons) || 0;
            const totalInners = parseInt(updatedModal.inners_send) || 0;
            const doneInners = parseInt(updatedModal.done_inners) || 0;
            const pouter = parseInt(updatedModal.outer) || 0; // Make sure to parse the inner and outer values correctly
            const pinner = parseInt(updatedModal.inner) || 0;

          
    
            // Always perform calculations when quantity or sendingInner changes
            if (name === 'quantity') {
                if (sendingInner) {
                    if (updatedModal.did_Number || updatedModal.ito_Number) {
                        updatedModal.qty_to_send = qtyToSend ;
                        updatedModal.remaining_qty = (qtyToSend - (quantity * pinner));
                        updatedModal.inners_send = totalInners;
                        updatedModal.remaining_inners = (totalInners - quantity);
                        
                    }
                } else {
                    if (updatedModal.did_Number || updatedModal.ito_Number) {
                        updatedModal.qty_to_send = qtyToSend ;
                        updatedModal.remaining_qty = (qtyToSend - (quantity * pouter));
                        updatedModal.cartons_send = totalCartons;
                        updatedModal.remaining_cartons = totalCartons - quantity;
                    }
                }
            }
    
            return { ...prevFormData, modal: updatedModal };
        });
    };
    

    useEffect(() => {

        props.palletsMasterDataAction();
        hasPermission('Sync') && props.syncPriceStatusAction();
        (props.userStore || props.callApi) && getPallets();
        props.updateFormData({ callApi: '' });

        return () => {
            if (props.history.location.pathname.indexOf(webUrl.addUpdatePallet) < 0)
                props.unmountPalletsAction();
        }
    }, []);

    const getPallets = (e) => {

        getPalletData(pageNo, e);
    }

    const onFieldChange = (name, value) => {

        setSearchData({
            ...searchData,
            [name]: value
        });
    }

    const onModalFieldChange = (name, value) => {

        let _formData = searchData;
        _formData.modal[name] = value;
        setSearchData({ ..._formData });
    }

    const getPalletData = (page = 1, event) => {

        let request = { page: page };

        if (searchData) {

            if (props.userStore) request.store_id = props.userStore;
            else if (searchData.searchStore) request.store_id = searchData.searchStore;
            if (searchData.searchPalletId) request.id = searchData.searchPalletId;
            if (searchData.searchStatus) request.status = searchData.searchStatus;
            if (searchData.searchCategory) request.category = searchData.searchCategory;
            if (searchData.searchContents) request.contents = searchData.searchContents;
            if (searchData.searchDescription) request.description = searchData.searchDescription;
            if (searchData.searchBarcode) request.barcode = searchData.searchBarcode;
        }

        props.palletsAction(request).then(() => {

            event && event.target.complete();
        });;

        setPageNo(page + 1);
    }

    const onSearchClick = () => {
        props.clearPalletsAction();
        setDetailsToShowIndex(undefined);
        setItemDetailsToShowIndex(undefined);
        getPalletData(1);
    }

    const onClearClick = () => {

        setSearchData({ modal: {} });
        props.clearPalletsAction();
        setDetailsToShowIndex(undefined);
        setItemDetailsToShowIndex(undefined);
        setPageNo(1);
    }

    const onRowClick = (index, id) => {

        if (index === detailsToShowIndex) {

            setDetailsToShowIndex(undefined);
        }
        else {

            props.palletItemsAction(id).then(() => {

                setDetailsToShowIndex(index);
            });
        }

        setItemDetailsToShowIndex(undefined);
    }

    const onItemRowClick = (index) => {
        index === itemDetailsToShowIndex
            ? setItemDetailsToShowIndex(undefined) :
            setItemDetailsToShowIndex(index);
    }

    const onDoneClick = () => {
        let ito = searchData.modal.did_Number || searchData.modal.ito_Number;
        let adjustedQuantity = sendingInner 
        ? searchData.modal.quantity * searchData.modal.inner 
        : searchData.modal.quantity * searchData.modal.outer;

        let itemData = {
            id: searchData.modal.id,
            pallet_id: palletId,
            quantity: adjustedQuantity,
            // did_Number: searchData.modal.did_Number,
            barcode: searchData.modal.barcode,
            outer: searchData.modal.outer,
            description: searchData.modal.description,
            inner: searchData.modal.inner,
            ito: ito,
            qty_to_send : searchData.modal.qty_to_send,
            remaining_qty: searchData.modal.remaining_qty,
            inners_send : searchData.modal.inners_send,
            remaining_inners: searchData.modal.remaining_inners,
            cartons_send : searchData.modal.cartons_send,
            remaining_cartons : searchData.modal.remaining_cartons,
            sendingInner: sendingInner,




            added_by: searchData.modal.id && searchData.modal.id > 0 ? searchData.addedBy : props.userName,
            added_on: searchData.modal.id && searchData.modal.id > 0 ? searchData.addedOn : getUtcDateTime()
        }

        if (searchData.modal.id && searchData.modal.id > 0) {
            props.updatePalletItemAction(itemData).then((response) => {
                response || setAlertMessage("Updation failed!!!");
                response || setShowAlert(true);
                response && props.palletItemsAction(palletId);
                response && closeModal();
                response && setPalletId(0);
                response && setPalletItemId(0);
                response && setItemDetailsToShowIndex(undefined);
            });
        }
        else {
            props.savePalletItemAction(itemData).then((response) => {
                response || setAlertMessage("Saved failed!!!");
                response || setShowAlert(true);
                response && props.palletItemsAction(palletId);
                response && closeModal();
                response && setPalletId(0);
            });
        }

    }

    const closeModal = () => {
        setShowModal(false);
        setShowStatusModal(false);
        setSearchData({
            ...searchData,
            status: '',
            currentStatus: '',
            modal: {}
        });
    }


    const onItemEdit = (data) => {
        setShowModal(true);
        setPalletItemId(data.id);
        setPalletId(data.pallet_id);
        let did_Number = data.ito && data.ito.startsWith('0000') ? data.ito : '';
        let ito_Number = data.ito && !data.ito.startsWith('0000') ? data.ito : '';
        let originalQuantity;
        if (sendingInner) {
            originalQuantity = data.quantity / data.inner;
        } else {
            originalQuantity = data.quantity / data.outer;
        }

        setSearchData({
            ...searchData,
            modal: {
                id: data.id,
                quantity: originalQuantity,
                // itoNumber: data.ito,
                did_Number: did_Number,
                ito_Number: ito_Number,
                barcode: data.barcode,
                outer: data.outer,
                description: data.description,
                inner: data.inner,
                addedBy: data.added_by,
                addedOn: data.added_on,

                qty_to_send : data.qty_to_send,
            remaining_qty: data.remaining_qty,
            inners_send : data.inners_send,
            remaining_inners: data.remaining_inners,
            cartons_send : data.cartons_send,
            remaining_cartons : data.remaining_cartons,
            sendingInner: data.sendingInner,
            }
        });
        setSendingInner(data.sendingInner === true || data.sendingInner === 'True');
    }

 

   
    const onBarcodeBlur = async (e) => {
        if (e && e.target && e.target.value) {
            const { modal } = searchData;
            const barcode = e.target.value;
          
    
            try {
                let didDescriptionResponse, itoDescriptionResponse;
                const quantity = parseInt(modal.quantity) || 0;
                let pinner = 0;
                let pouter = 0;
              
    
    
                // Use await to wait for the API call to complete
                const response = await props.getItemDescription(barcode);
                if (response || response[0]) {
                    pouter = response.Carton_Qty || 0;
                    pinner = Math.round(response.custom3) || 0;
                    onModalFieldChange('description', response.Short_Description || '');
                    onModalFieldChange('outer', pouter);
                    onModalFieldChange('inner', pinner);

                }
    
                // Conditionally call APIs based on filled fields
                if (modal.did_Number) {
                    didDescriptionResponse = await props.getDIDdescription(modal.did_Number, barcode);
                } else if (modal.ito_Number) {
                    itoDescriptionResponse = await props.getITOdescriptionNew(modal.ito_Number, barcode);
                }
    
                // Handle DID Description Response
                if (didDescriptionResponse && didDescriptionResponse[0]) {
                    const { qty_to_send, total_cartons, done_cartons, total_inners } = didDescriptionResponse[0];
                    if (sendingInner) {
                        onModalFieldChange('qty_to_send', qty_to_send);
                        onModalFieldChange('remaining_qty', ((qty_to_send - (quantity * pinner))));
                        onModalFieldChange('inners_send', total_inners);
                        onModalFieldChange('remaining_inners', (total_inners - quantity));
                        
                    } else {
                        onModalFieldChange('qty_to_send', qty_to_send);
                        onModalFieldChange('remaining_qty', ((qty_to_send - (quantity * pouter))));
                        onModalFieldChange('cartons_send', total_cartons);
                        onModalFieldChange('remaining_cartons', (total_cartons - done_cartons));
                    }
                }
    
                // Handle ITO Description Response
                if (itoDescriptionResponse && itoDescriptionResponse[0]) {
                    const { total_carton, total_qty, total_inner, done_qty, qty_to_send } = itoDescriptionResponse[0];
                    if (sendingInner) {
                        onModalFieldChange('qty_to_send', qty_to_send);
                        onModalFieldChange('remaining_qty', qty_to_send - (quantity*pinner));
                        onModalFieldChange('inners_send', total_inner);
                        onModalFieldChange('remaining_inners', total_inner - quantity);
                    } else {
                        onModalFieldChange('qty_to_send', qty_to_send);
                        onModalFieldChange('remaining_qty', qty_to_send - (quantity*pouter));
                        onModalFieldChange('cartons_send', total_carton);
                        onModalFieldChange('remaining_cartons', total_carton - done_qty);
                    }
                }
    
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    };

    

    const onSyncClick = () => {
        setShowSyncConfirm(false);
        props.syncPriceAction();
    }

    const onSyncConfirm = () => {
        setShowSyncConfirm(true);
        props.syncStatus ? setAlertMessage("Do you want to stop sync?") : setAlertMessage("Do you want to start sync?");
    }

    const onStatusEdit = (data) => {
        setShowStatusModal(true);
        setPalletId(data.id);
        let _status = JSON.parse(JSON.stringify(data.status));
        setUpdatedStatus(_status);
        setSearchData({
            ...searchData,
            currentStatus: data.status
        })
    }

    const changeStatus = (name, value) => {
        setUpdatedStatus(value);
    }

    const onDoneStatusClick = () => {

        if (updatedStatus === searchData.currentStatus) {
            closeModal();
            setPalletId(0);
            return;
        }

        if (updatedStatus === 'Dispatched') {

            setAlertMessage('Cannot change status to dispatched from this page. Please use dispatch page to dispatch the pallet')
            setShowAlert(true);
            return;
        }

        if (updatedStatus === 'Booked') {

            setAlertMessage('Cannot change status to booked from this page. Please use booking page to book the pallet')
            setShowAlert(true);
            return;
        }

        if (!isValidStatusToChange(updatedStatus, searchData.currentStatus)) {

            setAlertMessage('You can not change status from "' + searchData.currentStatus + '" to "' + updatedStatus + '"')
            setShowAlert(true);
            return;
        }

        let request = {}
        request.id = palletId;
        request.status = updatedStatus;
        request.user = props.userName;

        props.updatePalletShippingStatusAction(request).then((response) => {
            response || setAlertMessage("Updation failed!!!");
            response || setShowAlert(true);
            response && closeModal();
            response && setPalletId(0);
            response && onSearchClick();
            let palletData = { 
                id: request.id, 
                status: request.status,
                last_status_changed_by: request.user
            };
            props.savePalletAction(palletData)
                        .then(() => {
                            console.log('Pallet data sent to Odoo successfully');
                        })
                        .catch((error) => {
                            console.error('Error sending pallet data to Odoo:', error);
                        });

        });
    }

    const onDeletePallet = () => {
        setShowConfirm(false);
        props.deletePalletAction(palletId).then((response) => {
            response ?
                setAlertMessage("Deleted successfully!!!") :
                setAlertMessage("Deletion failed!!!");
            setShowAlert(true);
            setPalletId(0);
            response && getPalletData();
            response && setDetailsToShowIndex(undefined);
            response && setItemDetailsToShowIndex(undefined);
        });
    }

    const setStatusColor = (value) => {

        if (value && value.status) {
            if (value.status.toLowerCase() === 'in depot' && value.in_depot_date && getDayDiff(value.in_depot_date) > 3) {

                return 'in-depot-status';
            }
            else if (value.is_all_item_received != null && !value.is_all_item_received && value.status.toLowerCase() === 'received') {
                return 'variance-status';
            }
            else if (value.status.toLowerCase() === 'request to hold' || value.status.toLowerCase() === 'request to dispatch' || value.status.toLowerCase() === 'received') {
                return 'received-status';
            }
            else if (value.status.toLowerCase() === 'other (see notes)') {
                return 'others-status';
            }
        }
    }

    const onDeletePalletItem = () => {
        setShowItemConfirm(false);
        props.deletePalletItemAction(palletItemId).then((response) => {
            response ?
                setAlertMessage("Deleted successfully!!!") :
                setAlertMessage("Deletion failed!!!");
            setShowAlert(true);
            setPalletItemId(0);
            response && props.palletItemsAction(palletId);
            response && setItemDetailsToShowIndex(undefined);
        });
    }

    const palletItemsData = () => {
        return (

            props.palletItems && props.palletItems.length > 0 ?
                props.palletItems.map((value, index) => {

                    return (
                        <IonCard key={index} onClick={(e) => { e.stopPropagation(); onItemRowClick(index) }}>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol size='6'>
                                        <IonLabel className='head-label-small'><strong>{value.barcode}</strong></IonLabel>
                                    </IonCol>
                                    <IonCol size='3'>
                                        <IonLabel className='head-label-small'> Qty <strong>{value.quantity}</strong></IonLabel>
                                    </IonCol>
                                    <IonCol className='item-card-action' size='3'>

                                        {
                                            hasPermission('UpdatePalletItem') &&
                                            <IonIcon onClick={(e) => {
                                                e.stopPropagation();
                                                onItemEdit(value);
                                            }} icon={createOutline} className='item-head-icon' />
                                        }
                                        {
                                            hasPermission('DeletePalletItem') &&
                                            <IonIcon color='danger' onClick={(e) => {
                                                e.stopPropagation();
                                                setShowItemConfirm(true);
                                                setAlertMessage("Are you sure to delete?");
                                                setPalletItemId(value.id);
                                                setPalletId(value.pallet_id);
                                            }} icon={trashOutline} className='item-head-icon' />
                                        }

                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol>
                                        <IonLabel><strong>{value.description}</strong></IonLabel>
                                    </IonCol>
                                    {
                                        setItemStatusColor(value, statusOfRow) &&
                                        <IonCol size='2'>
                                            <IonFab><IonFabButton disabled className={'status-fab ' + setItemStatusColor(value, statusOfRow)}></IonFabButton></IonFab>
                                        </IonCol>
                                    }
                                </IonRow>
                                <IonRow >
                                    <IonCol>
                                        <IonLabel> DID no. <strong>{value.ito}</strong></IonLabel>
                                    </IonCol>
                                </IonRow>
                                {
                                    index === itemDetailsToShowIndex &&
                                    <>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel > Item Id <strong>{value.id}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>

                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Outer <strong>{value.outer}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Inner <strong>{value.inner}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Received <strong>{value.received_count}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Added by <strong>{value.added_by}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                    </>

                                }
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    const palletData = () => {

        return (

            props.pallets && props.pallets[0] && Array.isArray(props.pallets[0]) ?
                props.pallets[0].map((value, index) => {
                    //console.log("pallet",props.pallets);

                    return (
                        <IonCard key={index} onClick={() => { onRowClick(index, value.id); setStatusOfRow(value.status); }}>
                            <IonCardContent>
                                <IonRow>
                                    <IonCol sizeMd='2'>
                                        <IonLabel className='head-label'><strong> {value.id}</strong></IonLabel>
                                    </IonCol>
                                    {
                                        setStatusColor(value) &&
                                        <IonCol>
                                            <IonFab><IonFabButton disabled className={'status-fab ' + setStatusColor(value)}></IonFabButton></IonFab>
                                        </IonCol>
                                    }

                                    <IonCol className='card-action'>
                                        {
                                            hasPermission('DeletePallet') && <IonIcon color='danger' onClick={(e) => {
                                                e.stopPropagation();
                                                setShowConfirm(true);
                                                setAlertMessage("Are you sure to delete?");
                                                setPalletId(value.id);
                                            }} icon={trashOutline} className='header-icon' />

                                        }

                                        {
                                            hasPermission('UpdatePallet') &&
                                            <IonIcon onClick={(e) => {
                                                e.stopPropagation();
                                                props.history.push(webUrl.addUpdatePallet + '/' + value.id);
                                            }} icon={createOutline} className='header-icon' />
                                        }

                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol sizeMd='2'>
                                        <IonLabel><strong>{value.store_name}</strong></IonLabel>
                                    </IonCol>
                                    <IonCol>
                                        <IonLabel><strong>{value.status}</strong></IonLabel>
                                    </IonCol>
                                    <IonCol>
                                    <IonLabel><strong>{value.last_status_changed_date ? value.last_status_changed_date.split(" ")[0] : 'No Date'}</strong></IonLabel>
                                        {/* causes blank screen on pallet menu */}
                                       {/*<IonLabel><strong>{value.last_status_changed_date.split(" ")[0]}</strong></IonLabel>*/} 
                                    </IonCol>
                                    <IonCol>
                                        {
                                            hasPermission('UpdateStatus') &&
                                            <IonIcon onClick={(e) => {
                                                e.stopPropagation();
                                                onStatusEdit(value);
                                            }} icon={createOutline} className='item-content-icon' />
                                        }
                                    </IonCol>

                                </IonRow>

                                {
                                    index === detailsToShowIndex &&
                                    <div>
                                        {console.log("Received",value.received_date)}
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel>Category <strong>{value.category}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>

                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Consignment no. <strong>{value.con_number}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Supplier <strong>{value.supplier}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Freight Company <strong>{value.freight_company}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Pallet Type <strong>{value.pallet_type}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Weight <strong>{value.weight}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Wrapped on <strong>{convertTZ(value.wrapped_date, config.BRISBANE_TIME_ZONE)}</strong></IonLabel>
                                                {console.log("Wrapped",value.wrapped_date)}
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Wrapped by <strong>{value.wrapped_by}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Contents <strong>{value.Contents}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Sent on <strong>{convertTZ(value.sent_date, config.BRISBANE_TIME_ZONE)}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Received on <strong>{convertTZ(value.received_date, config.BRISBANE_TIME_ZONE)}</strong>
                                                {console.log("Received",value.received_date)}
                                                </IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Built by <strong>{value.built_by}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Last changed by <strong>{value.last_status_changed_by}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            <IonCol>
                                                <IonLabel> Last changed on <strong>{convertTZ(value.last_status_changed_date, config.BRISBANE_TIME_ZONE)}</strong></IonLabel>
                                            </IonCol>
                                        </IonRow>
                                        <IonRow>
                                            {
                                                hasPermission('AddPalletItem') &&
                                                <IonCol sizeMd='3'>
                                                    <IonButton className='button action-btn' type='button' onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowModal(true);
                                                        setPalletId(value.id);
                                                    }}>
                                                        Add Item
                                            </IonButton>
                                                </IonCol>
                                            }
                                            {
                                                hasPermission('PrintLabel') &&
                                                <IonCol sizeMd='3'>
                                                    <IonButton className='button action-btn' type='button' onClick={(e) => {
                                                        e.stopPropagation();
                                                        printLabel(value);
                                                    }}>
                                                        Print Label
                                                </IonButton>
                                                </IonCol>
                                            }

                                        </IonRow>
                                        <IonCard className='pallet-items'>
                                            <IonCardHeader>
                                                <IonCardTitle>
                                                    Pallet Items
                                            </IonCardTitle>
                                            </IonCardHeader>

                                            <IonCardContent className='pallet-items'>
                                                {palletItemsData()}
                                            </IonCardContent>
                                        </IonCard>
                                    </div>

                                }
                            </IonCardContent>
                        </IonCard >)
                })
                : <IonCard><IonCardContent>No record</IonCardContent></IonCard>
        );
    }

    return (
        <IonPage>
            <AppHeader headerText='Pallets' redirectTo={props.history.push}
                showAddPalletIcon={hasPermission('AddPallet')}
                showBookingIcon={hasPermission('Booking')}
                showDispatchIcon={hasPermission('Dispatch')}
                showSyncIcon={hasPermission('Sync')}
                onSyncClick={onSyncConfirm}
                syncStatus={props.syncStatus} />

            <div className="pallet-body">

                <IonCard className='search-criteria'>
                    <IonCardHeader className='search-criteria-header'>
                        <IonCardTitle> Search Criteria</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent className='search-field-section'>
                        <IonRow>
                            <IonCol sizeMd='4'>
                                <IonLabel> Pallet Id</IonLabel>
                                <IonInput name='searchPalletId' value={searchData.searchPalletId} onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}></IonInput>
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Store</IonLabel>
                                <SingleSelect
                                    name='searchStore'
                                    options={props.palletStore}
                                    optionValue='id'
                                    optionName='store_name'
                                    onChange={onFieldChange}
                                    disabled={!!props.userStore}
                                    value={props.userStore || searchData.searchStore} />
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Status</IonLabel>
                                <SingleSelect
                                    name='searchStatus'
                                    options={props.palletStatus}
                                    optionValue={(localStorage.getItem('userType') === 'manager' || localStorage.getItem('userType') === 'admin' || localStorage.getItem('userType') === 'buyer') ? 'status_name' : 'status'}
                                    optionName={(localStorage.getItem('userType') === 'manager' || localStorage.getItem('userType') === 'admin' || localStorage.getItem('userType') === 'buyer') ? 'status_name' : 'status'}
                                    onChange={onFieldChange}
                                    value={searchData.searchStatus} />
                            </IonCol>
                        </IonRow>
                        <IonRow>

                            <IonCol sizeMd='4'>
                                <IonLabel> Category</IonLabel>
                                <SingleSelect
                                    name='searchCategory'
                                    options={props.palletCategory}
                                    optionValue='category_name'
                                    optionName='category_name'
                                    onChange={onFieldChange}
                                    value={searchData.searchCategory} />
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Barcode</IonLabel>
                                <IonInput name='searchBarcode' value={searchData.searchBarcode} onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}></IonInput>
                            </IonCol>
                            <IonCol sizeMd='4'>
                                <IonLabel> Contents</IonLabel>
                                <IonInput name='searchContents' value={searchData.searchContents} onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}></IonInput>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                    <IonRow>
                        <IonCol sizeMd='4'>
                            <IonLabel> Description</IonLabel>
                            <IonInput name='searchDescription' value={searchData.searchDescription} onIonChange={(e) => { onFieldChange(e.target.name, e.target.value) }}></IonInput>
                        </IonCol>
                        <IonCol sizeMd='3'>
                            <IonButton className='button action-btn' type='button' onClick={onSearchClick}>
                                Search
                                </IonButton>
                        </IonCol>
                        <IonCol sizeMd='3'>
                            <IonButton className='button action-btn' type='button' onClick={onClearClick}>
                                Clear
                                </IonButton>
                        </IonCol>
                    </IonRow>
                </IonCard>

                <IonCard className='search-result pallet-items'>
                    <IonCardHeader className='search-field-section header-caption'>
                        <IonRow className='header-section'>
                            <IonCol>
                                <IonCardTitle> Search Result</IonCardTitle>
                            </IonCol>
                        </IonRow>
                        <IonRow className='legend-section'>
                            <IonCol size='4.5'>
                                <IonFab className='legend-item'>
                                    <IonFabButton disabled className='legend-fab received-status'></IonFabButton>
                                    <IonLabel className='v-center pad-2'>Received/Request</IonLabel>
                                </IonFab>
                            </IonCol>
                            <IonCol size='2.5'>
                                <IonFab className='legend-item'>
                                    <IonFabButton disabled className='legend-fab variance-status'></IonFabButton>
                                    <IonLabel className='v-center pad-2'>Variance</IonLabel>
                                </IonFab>
                            </IonCol>
                            <IonCol size='2.5'>
                                <IonFab className='legend-item'>
                                    <IonFabButton disabled className='legend-fab in-depot-status'></IonFabButton>
                                    <IonLabel className='v-center pad-2'>In Depot</IonLabel>
                                </IonFab>
                            </IonCol>
                            <IonCol size='2.5'>
                                <IonFab className='legend-item'>
                                    <IonFabButton disabled className='legend-fab others-status'></IonFabButton>
                                    <IonLabel className='v-center pad-2'>Others</IonLabel>
                                </IonFab>
                            </IonCol>
                        </IonRow>
                    </IonCardHeader>

                    <IonCardContent className='search-result-data'>
                        <IonContent>
                            {palletData()}
                            <IonInfiniteScroll threshold='100px' onIonInfinite={getPallets} disabled={props.pallets && props.pallets[0] && Array.isArray(props.pallets[0]) && props.pallets[0].length === props.pallets[1][0].count}>
                                <IonInfiniteScrollContent loadingSpinner='bubbles' loadingText='Loading more data...' />
                            </IonInfiniteScroll>
                        </IonContent>
                    </IonCardContent>
                </IonCard>
            </div>

            {/* <PalletItemModal modal={searchData.modal} showModal={showModal} onBarcodeBlur={onBarcodeBlur} 
            onModalFieldChange={onModalFieldChange} userName={searchData.modal.id && searchData.modal.id > 0 ? searchData.modal.addedBy : props.userName} 
            onDoneClick={onDoneClick} closeModal={closeModal} handleFieldChange={handleFieldChange} /> */}

                <PalletItemModal modal={searchData.modal} showModal={showModal} onBarcodeBlur={onBarcodeBlur}
                 onModalFieldChange={onModalFieldChange}
                //  onModalFieldChange={(name, value) => handleFieldChange(name, value)}
                  userName={props.userName} 
                // userName={formData.modal.id && formData.modal.id > 0 ? formData.modal.addedBy : props.userName}
                  onDoneClick={onDoneClick} closeModal={closeModal}
                 handleFieldChange={handleFieldChange} 
                 sendingInner={sendingInner}  
                 setSendingInner={setSendingInner} 
                 handleDidNumberBlur={handleDidNumberBlur} // Pass the DID Number blur handler
                 handleItoNumberBlur={handleItoNumberBlur}
                 />
            <AppAlert showAlert={showAlert} headerText='Message' message={alertMessage} btnCancelText="OK" cancelClick={() => { setShowAlert(false) }} />
            <AppAlert showAlert={showConfirm} headerText='Confirm' message={alertMessage} btnCancelText="Cancel" btnOkText="Yes" okClick={onDeletePallet} cancelClick={() => { setShowConfirm(false); setPalletId(0); }} />
            <AppAlert showAlert={showItemConfirm} headerText='Confirm' message={alertMessage} btnCancelText="Cancel" btnOkText="Yes" okClick={onDeletePalletItem} cancelClick={() => { setShowItemConfirm(false); setPalletItemId(0); }} />
            <AppAlert showAlert={showSyncConfirm} headerText='Confirm' message={alertMessage} btnCancelText="Cancel" btnOkText="Yes" okClick={onSyncClick} cancelClick={() => { setShowSyncConfirm(false); }} />
            <StatusModal palletStatus={props.palletStatus} status={updatedStatus} showModal={showStatusModal} onModalFieldChange={changeStatus} userName={props.userName} onDoneClick={onDoneStatusClick} closeModal={closeModal} />
        </IonPage>
    );
};

const mapStateToProps = state => ({
    palletStatus: state.pallet.palletStatus,
    palletStore: state.pallet.palletStore,
    palletCategory: state.pallet.palletCategory,
    pallets: state.pallet.pallets,
    palletItems: state.pallet.palletItems,
    userName: localStorage.getItem('userName'),
    userType: localStorage.getItem('userType'),
    userStore: getUserStore(),
    callApi: state.pallet.formData && state.pallet.formData.callApi,
    syncStatus: state.pallet.syncStatus
});

const mapDispatchToProps = dispatch => ({
    palletsMasterDataAction: () => dispatch(palletsMasterDataAction()),
    palletsAction: (data) => dispatch(palletsAction(data)),
    unmountPalletsAction: () => dispatch(unmountPalletsAction()),
    clearPalletsAction: () => dispatch(clearPalletsAction()),
    palletItemsAction: (id) => dispatch(palletItemsAction(id)),
    savePalletItemAction: (data) => dispatch(savePalletItemAction(data)),
    deletePalletAction: (id) => dispatch(deletePalletAction(id)),
    deletePalletItemAction: (id) => dispatch(deletePalletItemAction(id)),
    updatePalletItemAction: (data) => dispatch(updatePalletItemAction(data)),
    updatePalletShippingStatusAction: (data) => dispatch(updatePalletShippingStatusAction(data)),
    updateFormData: (data) => dispatch(updatePalletFormData(data)),
    getItemDescripton: (data) => dispatch(getItemDescriptionAction(data)),
    getDIDdescription: (did_reference, barcode) => dispatch(getDIDdescriptionAction(did_reference, barcode)),
    getITOdescriptionNew: (ito_reference, barcode) => dispatch(getITOdescriptionNewAction(ito_reference, barcode)),
    getdidnumbervalid: (did_number) => dispatch(getdidnumbervalidAction(did_number)),
    getitonumbervalid: (ito_number) => dispatch(getitonumbervalidAction(ito_number)),
    syncPriceAction: () => dispatch(syncPriceAction()),
    syncPriceStatusAction: () => dispatch(syncPriceStatusAction()),
    savePalletAction: (data) => dispatch(savePalletAction(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Pallet));
